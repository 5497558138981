@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInFromTopWithBounce {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  70% {
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes bumpText {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  70% {
    opacity: 0.7;
    transform: translateY(10px);
  }
  90% {
    opacity: 1;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bumpButton {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  70% {
    opacity: 0.7;
    transform: translateY(15px);
  }
  90% {
    opacity: 1;
    transform: translateY(-7px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
