@import url('https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');
body {
  margin: 0;
  font-family: 'Patrick Hand', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./assets/FlowerBackgroundImg.png'); /* Replace with your image path */
  background-size: cover; /* Cover the entire viewport */
  background-repeat: no-repeat; /* Prevent image repetition */
  background-attachment: fixed; /* Keep the background image fixed while scrolling */
  height: 100%;
  overflow: auto;

}

.container {
  width: 100%; /* Take full width */
  height: 100%;
  min-height: 1000px; /* You can adjust this value as needed */
  margin: 0; /* Remove default body margin */
  padding: 0; /* Remove default body padding */
  background-image: url('./assets/FlowerBackgroundImg.png'); /* Replace with your image path */
  background-size: cover; /* Cover the entire viewport */
  background-repeat: no-repeat; /* Prevent image repetition */
  background-attachment: fixed; /* Keep the background image fixed while scrolling */
  overflow: auto;
}

/* Apply responsive styles using Chakra UI's responsive utilities */
@media screen and (max-width: 768px) {
  body {
    overflow: auto;
  }

  .container {
    padding: 10px; /* Adjust padding for smaller screens */
    overflow: auto;
  }
}
